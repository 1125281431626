/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-process-env */
import { useEffect, useState } from "react";
import { Result } from "./result";

export function useEccSession(cognitoAccessToken?: string): Result<EccSession> {
    const [result, setResult] = useState<Result<EccSession>>({});

    useEffect(() => {
        let unmounted = false;

        function getEccSession({
            eccAuthOrigin,
            cognitoAccessToken,
        }: GetEccSession): void {
            fetch(`${eccAuthOrigin}/auth-api/get_session_federation_cognito`, {
                headers: {
                    Authorization: cognitoAccessToken,
                },
                credentials: "include",
            })
                .then((res) => {
                    if (!res.ok) {
                        throw new Error(`${res.status} ${res.statusText}`);
                    }
                    return res.json();
                })
                .then((data: EccSession) => {
                    if (unmounted) {
                        return;
                    }
                    setResult({
                        data: {
                            sign: data.sign,
                            origin: data.origin,
                        },
                    });
                })
                .catch((error) => {
                    if (unmounted) {
                        return;
                    }
                    setResult({ error });
                });
        }

        const eccAuthOrigin = process.env.ECC_AUTH_ORIGIN;
        if (eccAuthOrigin && cognitoAccessToken) {
            getEccSession({
                eccAuthOrigin,
                cognitoAccessToken,
            });
        }

        return () => {
            unmounted = true;
        };
    }, [cognitoAccessToken]);

    return result;
}

export type EccSession = {
    sign: string;
    origin: string;
};

type GetEccSession = {
    eccAuthOrigin: string;
    cognitoAccessToken: string;
};
