import { createContext, useMemo } from "react";
import { Result } from "./result";
import { useDatabaseFindGreedily } from "./use-database";
import { useEccSession } from "./use-ecc-session";
import { UserData } from "./use-user-data";

export const EccMenuContext = createContext<EccMenuUrl[] | undefined>(
    undefined,
);

export function useEccMenus(userData?: UserData): Result<EccMenuUrl[]> {
    const accountId = userData?.accountId;
    const eccSession = useEccSession(userData?.accessToken);
    const eccMenus = useDatabaseFindGreedily(
        "eccMenus",
        {
            index: "listEccMenusByAccountId",
            accountId,
        },
        {
            disabled: !accountId,
        },
    );

    /**
     * アカウントIDを取得する
     * ECC認証連携APIを呼ぶ
     * EccMenuテーブルからレコードを取得する
     * 配列を作成して返却する
     */

    const result: Result<EccMenuUrl[]> = useMemo(() => {
        const eccOrigin = eccSession.data?.origin;
        const eccUrls: EccMenuUrl[] = [];

        if (eccOrigin) {
            eccUrls.push({
                name: "監視メニュー",
                url: eccOrigin,
            });
        }

        if (eccOrigin && eccMenus.data) {
            eccMenus.data?.map((eccMenu) =>
                eccUrls.push({
                    name: eccMenu.name,
                    url: eccOrigin + eccMenu.path,
                }),
            );
        }
        return { data: eccUrls };
    }, [eccSession, eccMenus]);

    // ECC連携がなければ空の配列が返される
    return result;
}

export type EccMenuUrl = {
    name: string;
    url: string;
};
