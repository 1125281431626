/**
 * メール通報設定を操作する処理を定義します。
 */
export class NotificationController {
    /**
     * @param apiBaseUrl APIのURLオリジン (例： https://api.sereca.cloud )
     * @param stage APIのステージ (例： v1 )
     */
    // eslint-disable-next-line no-useless-constructor -- クラスのメンバー変数宣言があるため
    public constructor(
        private readonly apiBaseUrl: string,
        private readonly stage: string = "v1",
    ) {
        // No-op
    }

    /**
     * @param credentials 資格情報
     * @param target 取得対象
     * @returns メール通報設定
     */
    public async getNotificationEmail(
        { idToken }: NotificationCredentialsParameters,
        { tenantId, mailto }: NotificationEmailTargetParameters,
    ): Promise<NotificationEmail | undefined> {
        const url = createNotificationEmailUrl({
            apiBaseUrl: this.apiBaseUrl,
            stage: this.stage,
            tenantId,
            mailto,
        });
        const init = createFetchRequestInit({
            idToken,
            method: "GET",
        });

        const response = await fetch(url, init); // 4xx や 5xx も resolve. ネットワークエラーは reject.
        if (response.ok) {
            return response.json();
        }
        if (response.status === 404) {
            return Promise.resolve(undefined);
        }
        // console.error("response.ok:", response.ok);
        // console.error("response.status:", response.status);
        // console.error("response.statusText:", response.statusText);
        const error: Error = await NotificationHttpError.fromResponse(response);
        throw error;
    }

    /**
     * @param credentials 資格情報
     * @param target 設定対象と内容
     * @returns メール通報設定
     */
    public async putNotificationEmail(
        { idToken }: NotificationCredentialsParameters,
        {
            tenantId,
            mailto,
            ...parameters
        }: NotificationEmailTargetParameters &
            NotificationEmailSettableParameters,
    ): Promise<NotificationEmail> {
        const url = createNotificationEmailUrl({
            apiBaseUrl: this.apiBaseUrl,
            stage: this.stage,
            tenantId,
            mailto,
        });
        const init = createFetchRequestInit({
            idToken,
            method: "PUT",
            body: parameters,
        });

        const response = await fetch(url, init); // 4xx や 5xx も resolve. ネットワークエラーは reject.
        if (response.ok) {
            return response.json();
        }
        // console.error("response.ok:", response.ok);
        // console.error("response.status:", response.status);
        // console.error("response.statusText:", response.statusText);
        const error: Error = await NotificationHttpError.fromResponse(response);
        throw error;
    }
}

//------------------------------------------------------------------------------
// Classes
//------------------------------------------------------------------------------
/**
 * 通報設定 WEB API HTTPエラー
 */
export class NotificationHttpError extends Error {
    /**
     * ステータスコード
     */
    public readonly status: number;
    /**
     * ステータステキスト
     */
    public readonly statusText: string;

    /**
     * @param message メッセージ
     * @param status ステータスコード
     * @param statusText ステータステキスト
     */
    constructor(
        message: string | undefined,
        status: number,
        statusText: string,
    ) {
        super(message);
        this.name = new.target.name;
        this.status = status;
        this.statusText = statusText;
    }

    /**
     * @param response fetchのレスポンス
     * @returns HTTPエラー
     */
    public static async fromResponse(
        response: Response,
    ): Promise<NotificationHttpError> {
        let message: string | undefined;
        try {
            const body = await response.json();
            if (typeof body.message === "string") {
                message = body.message;
            }
        } catch (error) {
            // error は無視する
        }

        return new NotificationHttpError(
            message,
            response.status,
            response.statusText,
        );
    }
}

//------------------------------------------------------------------------------
// Consts
//------------------------------------------------------------------------------
/**
 * 状態リスト
 */
export const StatusTypes = {
    Enabled: "Enabled",
    Disabled: "Disabled",
} as const;

//------------------------------------------------------------------------------
// Types
//------------------------------------------------------------------------------
/**
 * 状態
 */
export type StatusType = typeof StatusTypes[keyof typeof StatusTypes];

/**
 * 資格情報のパラメータ
 */
export type NotificationCredentialsParameters = {
    /**
     * サインインユーザーのIDトークン
     */
    idToken: string;
};
/**
 * 通知設定対象
 */
export type NotificationEmailTargetParameters = {
    /**
     * 対象のテナントID
     */
    tenantId: string;
    /**
     * 対象のEメールアドレス
     */
    mailto: string;
};

/**
 * メール通報設定
 */
export type NotificationEmail = {
    /**
     * 送信先
     */
    mailto: string;
    /**
     * 状態
     */
    status: StatusType | string;
    /**
     * 宛先名称
     */
    name?: string;
    /**
     * 送信周期
     */
    sendIntervalMinutes: number;
    /**
     * 最終変更日時
     */
    modifiedAt?: string;
};

/**
 * メール通報設定・設定可能パラメータ
 */
export type NotificationEmailSettableParameters = Pick<
    NotificationEmail,
    "status" | "name" | "sendIntervalMinutes"
>;

/**
 * エラーレスポンス
 */
export type ErrorResponse = {
    /**
     * メッセージ
     */
    message?: string;
};

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------
/**
 * 通報設定APIのURL生成用パラメータ
 */
type CreateNotificationEmailUrlParameters = {
    /**
     * APIのベースURL
     */
    apiBaseUrl: string;
    /**
     * APIのステージ
     */
    stage: string;
    /**
     * 対象のテナントID
     */
    tenantId: string;
    /**
     * 対象のEメールアドレス
     */
    mailto: string;
};

/**
 * @param parameters パラメータ
 * @returns メール通報設定APIのURL (例： https://api.sereca.cloud/tenant-id/notification/v1/emails/user-mailto%40tenant.com )
 */
function createNotificationEmailUrl({
    tenantId,
    stage,
    mailto,
    apiBaseUrl,
}: CreateNotificationEmailUrlParameters): string {
    return `${apiBaseUrl}/${tenantId}/notification/${stage}/emails/${encodeURIComponent(
        mailto,
    )}`;
}

/**
 * MIMEタイプ application/json
 */
const MIME_TYPE_JSON = "application/json";

/**
 * fetch 初期設定生成用パラメータ
 */
type CreateFetchRequestInitParameters = {
    /**
     * サインインユーザーのIDトークン
     */
    idToken: string;
    /**
     * HTTP メソッド
     */
    method: "GET" | "PUT" | "DELETE";
    /**
     * リクエストボディ
     */
    body?: unknown;
};

/**
 *
 * @param parameters パラメータ
 * @returns fetch 初期設定
 */
function createFetchRequestInit({
    idToken,
    method,
    body,
}: CreateFetchRequestInitParameters): RequestInit {
    const headers = [
        // 認証情報 (Bearerは大文字始まりでないとAPI GatewayのJWTオーソライザーに拒否される)
        ["authorization", `Bearer ${idToken}`],
        // クライアント側が処理できるデータ形式
        ["accept", MIME_TYPE_JSON],
    ];

    if (body !== undefined) {
        // content-type: リクエストボディのデータ形式
        headers.push(["content-type", `${MIME_TYPE_JSON}; charset=UTF-8`]);
    }

    return {
        // モード設定 : cors -- クロスオリジンリクエストを許可
        mode: "cors",
        // キャッシュ設定 : no-cache -- 条件付きリクエスト (etag) を送信し、304が返されたらHTTPキャッシュを使用する
        cache: "no-cache",
        // 資格情報設定 : include -- 常にユーザーの資格情報 (クッキー、認証ヘッダーなど) を送信する
        credentials: "include",
        // リクエストメソッド
        method,
        // リクエストヘッダー
        headers: Object.fromEntries(headers),
        // リクエストボディ
        body: body === undefined ? undefined : JSON.stringify(body),
    };
}
